import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Select from 'react-select'
import Collapse from 'react-bootstrap/Collapse'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownWideShort, faPlusCircle, faCircleInfo, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
// import { capitalize } from 'lodash'
import { apiAuthInstance } from '../../apis/backend'
import { TitledControl, TitledOptions, purpleSelector } from '../../components/shared/ReactSelectData'
import LoadingButton from '../../components/shared/LoadingButton'
import TableList from '../../components/shared/TableList'
import ModalApiStatus from "../../components/modal/ModalApiStatus"
import ModalSubmitWarning from "../../components/modal/ModalSubmitWarning"

export default function WhatsappTemplate(props) {
    const { settings } = props
    const { t, i18n } = useTranslation('SmsTemplate', 'common')

    const initModalData = {
        name: '',
        header_message: '',
        body_message: '',
    }
    const initModalAction = {
        type: '',
        targetId: undefined,
    }
    const initModal = {
        data: initModalData,
        action: initModalAction,
        loaded: true,
        show: false,
        viewModalShow: false,
        warningModalShow: false,
    }
    const [modal, setModal] = useState(initModal)

    const initApiStatus = {
        status: '',
        message: '',
        apiStatusModalShow: false,
    }
    const [apiStatus, setApiStatus] = useState(initApiStatus)

    const initTableList = []
    const [tableList, setTableList] = useState(initTableList)

    async function getTemplates() {
        await apiAuthInstance({
            url: 'whatsapp/templates/',
            method: 'get',
            params: {
                type: 'all'
            }
        })
        .then((response) => {
            setTableList(response.data.data)
        })
    }

    async function postTemplate() {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/templates/`,
            method: 'post',
            data: {
                name: modal.data.name,
                header_message: modal.data.header_message,
                body_message: modal.data.body_message,
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            getTemplates()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function putTemplate(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/templates/${id}/`,
            method: 'put',
            data: {
                name: modal.data.name,
                header_message: modal.data.header_message,
                body_message: modal.data.body_message,
            }
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            getTemplates()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }

    async function deleteTemplate(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/templates/${id}/`,
            method: 'delete',
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            getTemplates()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })

        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function getTemplate(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/templates/${id}/`,
            method: 'get',
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    name: response.data.data.name,
                    header_message: response.data.data.header_message,
                    body_message: response.data.data.body_message,
                },
                loaded: true,
            }))
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function replicateTemplate(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/templates/${id}/replicate/`,
            method: 'post',
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            getTemplates()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })

        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }
    async function approveTemplate(id) {
        setModal(prevState => ({
            ...prevState,
            loaded: false
        }))
        await apiAuthInstance({
            url: `whatsapp/templates/${id}/approve/`,
            method: 'post',
        })
        .then((response) => {
            setModal(prevState => ({
                ...prevState,
                data: initModalData,
                loaded: true,
                show: false,
                viewModalShow: false,
                warningModalShow: false
            }))
            getTemplates()
            setApiStatus({
                status: response.data.status,
                message: response.data.message,
                apiStatusModalShow: true,
            })
        })
        .catch((error) => {
            setApiStatus({
                status: error.response.data.status,
                message: error.response.data.message,
                apiStatusModalShow: true,
            })
        })
    }


    const handleModalClose = () => {
        setModal(initModal)
        setApiStatus(initApiStatus)
    }

    const handleModalShow = (event) => {
        let selected_template = undefined
        switch (event.target.name) {
            case 'create':
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'create'
                    },
                    show: true,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'view':
                selected_template = tableList.find(item => item.id.toString() === event.target.value.toString())
                getTemplate(selected_template.id)
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'view',
                        targetId: selected_template.id,
                    },
                    show: true,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'delete':
                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'delete',
                        targetId: event.target.value,
                    },
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true,
                }))
                break
            case 'approve':
                selected_template = tableList.find(item => item.id.toString() === event.target.value.toString())

                setModal(prevState => ({
                    ...prevState,
                    action: {
                        type: 'approve',
                        targetId: selected_template.id,
                    },
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true,
                }))
                break
            default:
                break
        }
    }
    const handleModalSubmit = (event) => {
        switch (event.target.name) {
            case 'create':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true
                }))
                break
            case 'view':
                setModal(prevState => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: true
                }))
                break
            default:
                break
        }
    }
    const handleWarningModalCancel = (event) => {
        switch (event.target.name) {
            case 'create':
                setModal((prevState) => ({
                    ...prevState,
                    show: true,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'delete':
                setModal((prevState) => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            case 'approve':
                setModal((prevState) => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
            default:
                setModal((prevState) => ({
                    ...prevState,
                    show: false,
                    viewModalShow: false,
                    warningModalShow: false,
                }))
                break
        }
    }
    const handleWarningModalSubmit = (event) => {
        switch (event.target.name) {
            case 'create':
                postTemplate()
                break
            case 'view':
                putTemplate(modal.action.targetId)
                break
            case 'delete':
                deleteTemplate(modal.action.targetId)
                break
            case 'approve':
                approveTemplate(modal.action.targetId)
                break
            default:
                break
        }
    }

    // const [sort, setSort] = useState({ name: 'create_time_new_to_old', value: { key: 'created_at', type: 'date', aesc: false }})
    // const sortOptions = [
    //     { name: 'create_time_new_to_old', value: { key: 'created_at', type: 'date', aesc: false }},
    //     { name: 'create_time_old_to_new', value: { key: 'created_at', type: 'date', aesc: true }},
    //     { name: 'update_time_new_to_old', value: { key: 'updated_at', type: 'date', aesc: false }},
    //     { name: 'update_time_old_to_new', value: { key: 'updated_at', type: 'date', aesc: true }},
    // ]
    // const [search, setSearch] = useState('')

    useEffect(() => {
        getTemplates()
    }, [])

    return (
        <div className="h-100 d-flex flex-column Table-AutoWidth">
            <Row xs="auto" className="mb-2">
                <Col>
                    <Button variant="orcaLight" name="create" onClick={handleModalShow}>
                        <FontAwesomeIcon icon={faPlusCircle} className="pe-2" />
                        {t('create_template', { ns: 'common'})}
                    </Button>
                </Col>
            </Row>
            <TableList settings={{
                tableConfig: [
                    {
                        "head": "name",
                        "dataType": "text",
                        "fieldName": "name"
                    },
                    {
                        "head": "status",
                        "dataType": "status",
                        "fieldName": "status"
                    },
                    {
                        "head": "create_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "create_time"
                    },
                    {
                        "head": "update_time",
                        "dataType": "datetime-tz+8",
                        "fieldName": "edit_time"
                    },
                    {
                        "head": "functions",
                        "dataType": "view and delete and approve",
                        "fieldAsValue": "id",
                        "onClick": handleModalShow,
                    }
                ],
                "data": tableList,
                "size": 10,
                "pagination": true,
                }}
            />
            <ModalSubmitWarning
                settings={{
                submitButton: {
                    name: modal.action.type,
                    value: modal.action.targetId,
                    content: 'Submit',
                    loaded: modal.loaded,
                },
                warningModalShow: modal.warningModalShow,
                handleWarningModalCancel,
                handleModalSubmit: handleWarningModalSubmit,
                handleModalClose,
                warningMessageType: modal.action.type,
                }}
            />
            <ModalApiStatus
                settings={{
                content: {
                    status: apiStatus.status,
                    message: apiStatus.message,
                },
                apiStatusModalShow: apiStatus.apiStatusModalShow,
                handleModalClose,
                }}
            />

            {/* 建立模板視窗 */}
            <Modal show={modal.show} size="md">
                <Modal.Header closeButton onHide={handleModalClose}>
                { modal.action.type === 'create' && <Modal.Title>{t('create_template', { ns: 'common'})}</Modal.Title> }
                { modal.action.type === 'view' && <Modal.Title>{t('edit_template', { ns: 'common'})}</Modal.Title> }
                </Modal.Header>
                <Modal.Body>
                    <Form className="pt-3 pb-0">
                        <Form.Group className="mb-3">
                            <Col>
                                <Form.Group className="mb-2" controlId="subject_form">
                                    <Form.Label className="h6 text-orca">{t('template_name', { ns: 'common'})}</Form.Label>
                                    <Form.Control maxLength="40" name="name" defaultValue={modal.data.name} type="text" placeholder="模板名稱" onChange={(event) => setModal({...modal, data: {...modal.data, name: event.target.value}})} />
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="subject_form">
                                {/* <Form.Label className="h6 text-orca">{t('header_message')}</Form.Label> */}
                                <Form.Label className="h6 text-orca">{t('Header 訊息')}</Form.Label>
                                    <Form.Control maxLength="40" name="name" defaultValue={modal.data.header_message} type="text" placeholder="Header" onChange={(event) => setModal({...modal, data: {...modal.data, header_message: event.target.value}})} />
                                </Form.Group>
                                <Form.Group className="mb-2" controlId="subject_form">
                                {/* <Form.Label className="h6 text-orca">{t('body_message')}</Form.Label> */}
                                <Form.Label className="h6 text-orca">{t('Body 訊息')}</Form.Label>
                                    <Form.Control name="message" value={modal.data.body_message} as="textarea" rows={10} style={{ resize: "none" }} onChange={(event) => setModal({...modal, data: {...modal.data, body_message: event.target.value}})} />
                                </Form.Group>
                            </Col>

                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" name="modal-cancel" onClick={handleModalClose}>
                        {t('cancel', { ns: 'common'})}
                    </Button>
                    <LoadingButton settings={{
                        "name": modal.action.type,
                        // "value": modal.action.targetId,
                        "content": "送出",
                        "loaded": modal.loaded,
                        "onClick": handleModalSubmit
                    }} />
                </Modal.Footer>
            </Modal>

            {/* 刪除模板 */}

        </div>
    )
}